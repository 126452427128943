import ApiDefault from "./ApiDefault";

const PATH = 'crm/apartment-board';

export default class ApartmentBoard extends ApiDefault {

    async list() {
        return this.get(PATH);
    }

    async deleteFromBoard(data) {
        return this.post(PATH + "/delete-from-board", data)
      }

    async moveApartment(data) {
        return this.patch(PATH + "/move-apartment", data)
    }

    async clientAll(clientId) {
        return this.get(PATH + "/client/" + clientId);
    }

    async copyApartment(data) {
        return this.patch(PATH + "/copy-apartment", data)
    }
}
