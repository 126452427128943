import ClearIcon from "@mui/icons-material/Clear";
import { InputAdornment, MenuItem, TextField, Select, Button } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";

export const CustomRangeSelect = ({ initialState, isForm, options, placeholder, symbol, value, onChange, openSelect, setOpenSelect, focusedRowIndex, tableRefs }) => {
  const { t } = useTranslation(["translation", "form"]);

  const handleOptionChange = (event) => {

    if (document.activeElement.tagName !== 'BUTTON') {
      setOpenSelect(false)
      onChange(event.target.value);
    }
  };

  const handleInputFromChange = (event) => {
    const price = event.target.value.replace(/[^0-9]/g, '');
    const from = price ? Number.parseInt(price) : "";

    onChange({ ...value, from });
  };

  const handleInputToChange = (event) => {
    const price = event.target.value.replace(/[^0-9]/g, '');
    const to = price ? Number.parseInt(price) : "";

    onChange({ ...value, to });
  };

  const handleClear = () => onChange(initialState);

  return (
    <Select
        fullWidth
        open={openSelect}
        onOpen={() => {
          setOpenSelect(true);
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        onClose={() => {
          setOpenSelect(false);
          setTimeout(() => {
            document.activeElement.blur();
            if (tableRefs.current[focusedRowIndex]) {
                tableRefs.current[focusedRowIndex].focus();
            }
          }, 0);
        }}
        onChange={handleOptionChange}
        size="small"
        renderValue={(selected) => selected.from && selected.to
            ? `${t('from')} ${selected.from}${symbol} ${String(t('to')).toLocaleLowerCase()} ${selected.to}${symbol}`
            : selected.from ? `${t('from')} ${selected.from}${symbol}`
            : selected.to ? `${t('to')} ${selected.to}${symbol}` : ''}
        endAdornment={
            <ClearIcon
            fontSize="small"
            onClick={handleClear}
            sx={{ display: value.from || value.to ? "" : "none", cursor: "pointer" }}
            />
        }
 
        sx={{
            mb: 0.5,
            ml: 0,
            mt: isForm ? 1 : 0,
            '& .MuiSelect-select .notranslate::after': placeholder ? {
            content: `"${placeholder}"`, opacity: 0.42,
            } : {},
            "& .MuiSelect-icon": { display: value.from || value.to ? 'none' : '' }
        }}
        value={!value.from && !value.to ? "" : value}
        variant={isForm ? "filled" : "outlined"}
    >
      {options.map((option, key) => <MenuItem key={key}
                                              value={{ from: option.from, to: option.to }}>{option.name}</MenuItem>)}
      <MenuItem onKeyDown={(e) => e.stopPropagation()}>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{symbol}</InputAdornment>
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={handleInputFromChange}
          placeholder={t('from')}
          value={value.from}
          variant="filled"
        />
      </MenuItem>
      <MenuItem onKeyDown={(e) => e.stopPropagation()}>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{symbol}</InputAdornment>
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={handleInputToChange}
          placeholder={t('to')}
          value={value.to}
          variant="filled"
        />
      </MenuItem>

      <Button
            size="small"
            variant="contained"
            sx={{
                display: 'block',
                margin: '10px auto',
                color: (theme) => theme.palette.main,
                boxShadow: 'none',
                p: "3px 8px",
                '&:hover': {
                    boxShadow: 'none',
                    color: "white"
                },
            }}
            onClick={() => {
                setOpenSelect(false);
                setTimeout(() => {
                  document.activeElement.blur();
                  if (tableRefs.current[focusedRowIndex]) {
                      tableRefs.current[focusedRowIndex].focus();
                  }
                }, 0);
            }}
        >
            {t('oksave')}
        </Button>

    </Select>
  );
};
