import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { getAddress } from "helpers";
import React from "react";
import ApartmentInformation from "./ApartmentInformation";

export default function ApartmentAccordion({ apartment, error, handleSubmit, index, refProp }) {

  const style = error ? { border: "1px solid red" } : {};

  return <Accordion sx={{border: '1px solid #00000020', marginBottom: '1px'}} key={index}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={apartment.id + "-header"} sx={style}>
      <DragIndicatorIcon className="column-drag-handle" fontSize="small" sx={{ color: "#141414", mr: 2 }} />
      <Typography sx={{}}>{getAddress(apartment)}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ApartmentInformation apartment={apartment} handleSubmit={handleSubmit} key={index + apartment.id} ref={refProp} />
    </AccordionDetails>
  </Accordion>;
}