import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import { useMemo, useState } from "react";
import { Checkbox } from "components";
import { SelectableTableHeader } from "./SelectableTableHeader";

export function ReportTable(
  {
    handleRowClick,
    headCells,
    height = "100%",
    loading,
    isSelectable,
    rows,
    rowsPerPage = 10,
    selected,
    setSelected,
    size = "medium"
  }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(headCells.length ? headCells[0].id : 'id');
  const [page, setPage] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (!isSelectable) {
      return;
    }
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    if (!isSelectable) {
      return
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const visibleHeadCells = useMemo(() => headCells.filter(cell => !cell.hasOwnProperty('visible') || cell.visible),
    [headCells]);

  return (
    <Box sx={{ width: '100%', overflowX: 'auto', height: height }}>
      <TableContainer sx={{ maxHeight: "calc(100% - 60px)" }}>
        <Table
          size={size}
          stickyHeader
        >
          <SelectableTableHeader
            headCells={visibleHeadCells}
            isSelectable={isSelectable}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {loading ?
              [...Array(5)].map((_, index) => <TableRow key={index}>
                  {visibleHeadCells.map((_, index) => <TableCell key={index}><Skeleton /></TableCell>)}
                </TableRow>
              )
              : visibleRows.map((row, index) => {
                const isItemSelected = isSelectable && isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleRowClick ? handleRowClick(row.id) : handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {isSelectable && <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClick(event, row.id)
                      }}
                    />
                  </TableCell>
                  }

                  {visibleHeadCells.map((headCell, key) => (
                    <TableCell key={key} align={headCell.numeric ? "center" : "left"}
                               style={{ whiteSpace: 'nowrap' }}>{row[headCell.id]}</TableCell>
                  ))}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    // Handle numeric values
    const numA = parseFloat(valueA.replace(/[^0-9.-]+/g, ''));
    const numB = parseFloat(valueB.replace(/[^0-9.-]+/g, ''));
    if (!isNaN(numA) && !isNaN(numB)) {
      return numB - numA;
    }
  }

  // Handle non-numeric values or mixed types
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
