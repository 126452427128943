import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Loader, PrivateRoute } from "components";
import { history, useDocumentTitle } from 'helpers';
import { createContext, lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Notification from "./components/Notification";
import { themeConfig } from "./config";
import { useTranslation } from "react-i18next";

const PresentationModule = lazy(() => import('./modules/presentation/PresentationModule'));
const PresentationPreviewModule = lazy(() => import('./modules/presentation/PresentationPreviewModule'));
const AuthModule = lazy(() => import('./modules/auth/AuthModule'));
const CrmModule = lazy(() => import('./modules/main/CrmModule'));
const UserModule = lazy(() => import('./modules/user/UserModule'));
const DBModule = lazy(() => import('./modules/db/DBModule'));
const M2Module = lazy(() => import('./modules/m2/M2Module'));
const NotFound = lazy(() => import('./NotFound'));

export const MUIWrapperContext = createContext({
  toggleColorMode: () => {},
});

export const addColoModeClassToBody = (className) => {
  const bodyEl = document.querySelector('body');
  bodyEl.classList.remove("cm-dark");
  bodyEl.classList.remove("cm-light");

  bodyEl.classList.add("cm-" + className);
}

const pathToSkip = [
  '/login',
  '/presentation',
  '/advantages',
  '/prices',
  '/product',
  '/about-us',
  '/activate',
  '/reset',
  '/agreement'
];

const findMode = () => {
  const pathname = window.location.pathname;
  if (pathToSkip.find(item => pathname === item)) {
    return 'light';
  }
  const localStorageMode = window.localStorage.getItem('mode');

  if (localStorageMode === 'light' || localStorageMode === 'dark') {
    return localStorageMode;
  }

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }

  return 'light';
}

export default function App() {
  const { user } = useSelector(store => store.auth);
  const { t } = useTranslation(['translation']);
  history.navigate = useNavigate();
  const location = useLocation();
  history.location = location;
  useDocumentTitle(t("main"));

  useEffect(() => {
    user && location.pathname !== "/" && !pathToSkip.some(path => location.pathname.startsWith(path)) && localStorage.setItem('location', location.pathname)
  }, [location, user]);

  const [mode, setMode] = useState(findMode());

  useEffect(() => {
    addColoModeClassToBody(mode);
  }, [mode]);

  const muiWrapperUtils = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const nextMode = prevMode === "light" ? "dark" : "light";
          addColoModeClassToBody(nextMode);
          return nextMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme(themeConfig[mode]),
    [mode]
  );

  return (<MUIWrapperContext.Provider value={muiWrapperUtils}>
    <ThemeProvider theme={theme}>
      <Notification />
      <Suspense fallback={<Loader open={true} />}>
      <Routes>
        <Route path="presentation/:id/*" element={<PresentationModule />} />
        <Route path="presentation/current/preview/*" element={<PresentationPreviewModule />} />
        <Route path="crm/*" element={<PrivateRoute><CrmModule /></PrivateRoute>} />
        <Route path="user/*" element={<PrivateRoute><UserModule /></PrivateRoute>} />
        <Route path="db/*" element={<PrivateRoute><DBModule /></PrivateRoute>} />
        <Route path="m2/*" element={<PrivateRoute><M2Module /></PrivateRoute>} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="reset/:resetToken/*" element={<AuthModule />} />
        <Route path="activate/:activateToken/*" element={<AuthModule />} />
        <Route path="login" element={<AuthModule login={true}/>} />
        <Route path="*" element={<AuthModule />} />
      </Routes>
      </Suspense>
    </ThemeProvider>
  </MUIWrapperContext.Provider>);
}
