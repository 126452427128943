import { ListSubheader, MenuItem, Select, Button } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";

export const CustomLabeledSelect = ({ name, onChange, options, value, openSelect, setOpenSelect, focusedRowIndex, tableRefs }) => {

  const { t } = useTranslation(["translation", "form"]);

  return (
    <Select 
        fullWidth
        sx={{fontSize: '14px'}}
        size="small"
        name={name}
        // open={openSelect}
        onOpen={() => {
          setOpenSelect(true);
          setTimeout(() => {
              // document.activeElement.blur();
          }, 0);
        }}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
            if (tableRefs.current[focusedRowIndex]) {
              tableRefs.current[focusedRowIndex].focus();
            }
          }, 0);
        }}
        value={value}
        onChange={onChange}

        // MenuProps={{
        //   onClick: e => {
        //     // e.preventDefault();

        //     document.activeElement.blur();
        //     if (tableRefs.current[focusedRowIndex]) {
        //       tableRefs.current[focusedRowIndex].focus();
        //     }

        //     }
        //   }}
    >
        {
            options.map((option, key) => option.value !== undefined
            ? <MenuItem key={key} value={option.value}>{option.name}</MenuItem>
            : <ListSubheader key={key} sx={{ backgroundColor: "grey.main" }}>{option.name}</ListSubheader>
            )
        }

        {/* <Button
            size="small"
            variant="contained"
            sx={{
                display: 'block',
                margin: '10px auto',
                color: (theme) => theme.palette.main,
                boxShadow: 'none',
                p: "3px 8px",
                '&:hover': {
                    boxShadow: 'none',
                    color: "white"
                },
            }}
            onClick={() => {
                setOpenSelect(false);
                setTimeout(() => {
                    document.activeElement.blur();
                    if (tableRefs.current[focusedRowIndex]) {
                        tableRefs.current[focusedRowIndex].focus();
                    }
                }, 0);
            }}
        >
            {t('oksave')}
        </Button> */}
    </Select>
  );
}
