import ClearIcon from "@mui/icons-material/Clear";
import { ListItemText, MenuItem, Select, Button } from "@mui/material";
import { Checkbox } from "components";
import { useTranslation } from "react-i18next";
import React from "react";

export const CustomCheckboxSelect = ({ name, onChange, onClear, options, placeholder, value, openSelect, setOpenSelect, focusedRowIndex, tableRefs }) => {

    const { t } = useTranslation(["translation", "form"]);

    return (
        <Select
            fullWidth
            multiple
            open={openSelect}
            onOpen={() => {
                setOpenSelect(true);
                setTimeout(() => {
                    document.activeElement.blur();
                }, 0);
            }}
            onClose={() => {
                setOpenSelect(false);
                setTimeout(() => {
                    document.activeElement.blur();
                    if (tableRefs.current[focusedRowIndex]) {
                        tableRefs.current[focusedRowIndex].focus();
                    }
                }, 0);
            }}
            endAdornment={
                <ClearIcon fontSize="small" onClick={onClear} sx={{ display: value.length > 0 ? "" : "none", cursor: "pointer" }} />
            }
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            renderValue={(selected) => options.filter((option) => selected.includes(option.value)).map((option) => option.name).join(', ')}
            size="small"
            sx={{
                fontSize: "14px",
                mb: 0.5, '& .MuiSelect-select .notranslate::after': placeholder ? {
                content: `"${placeholder}"`, opacity: 0.42,
                } : {},
                "& .MuiSelect-iconOutlined": { display: value.length > 0 ? 'none' : '' }
            }}
            value={value}
            >
            {options.map((option, key) => <MenuItem
                key={key}
                value={option.value}
            >
                <Checkbox checked={value.includes(option.value)} />
                <ListItemText primary={option.name} />
            </MenuItem>)}

            <Button
                size="small"
                variant="contained"
                sx={{
                    display: 'block',
                    margin: '10px auto',
                    color: (theme) => theme.palette.main,
                    boxShadow: 'none',
                    p: "3px 8px",
                    '&:hover': {
                        boxShadow: 'none',
                        color: "white"
                    },
                }}
                onClick={() => {
                    setOpenSelect(false);
                    setTimeout(() => {
                        document.activeElement.blur();
                        if (tableRefs.current[focusedRowIndex]) {
                            tableRefs.current[focusedRowIndex].focus();
                        }
                    }, 0);
                }}
            >
                {t('oksave')}
            </Button>

        </Select>
    );
}
