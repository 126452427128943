import ApiDefault from "./ApiDefault";

const PATH = 'crm/client';

export default class Client extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async all() {
    return this.get(PATH);
  }

  async once(id, page) {
    return this.get(PATH + '/' + id + (page > 1 ? '?page='+ String(page) : ''));
  }

  async createComment(id, data) {
    return this.post(PATH + '/' + id + '/comment', data);
  }

  async delete(id) {
    return this.remove(PATH + '/' + id);
  }

  async getOne(id) {
    return this.get(PATH + '/' + id);
  }

  async updateOrder(data) {
    return this.put(PATH + "/update-order", data);
  }
}
