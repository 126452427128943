import { Grid } from "@mui/material";
import { apartmentApi } from "helpers";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AddressAutocomplete } from "./AddressAutocomplete";
import { LabeledAsyncSelect } from "./LabeledAsyncSelect";
import { LabeledInput } from "./LabeledInput";
import { InputPhoneMask } from "./InputPhoneMask"
import { LabeledSelect } from "./LabeledSelect";

export function MainInformationBlock({ disabled, formik }) {
  const { t } = useTranslation(["translation", "form"]);

  const [disabledCity, setDisabledCity] = useState(true);
  const [disabledStreet, setDisabledSreet] = useState(true);

  const [disabledCityRegion, setDisabledCityRegion] = useState(true);
  const [disabledCitySubregion, setDisabledCitySubregion] = useState(true);

  useEffect(() => {
    if (formik.values.state) {
      setDisabledCity(false);
    } else {
      setDisabledCity(true);
    }

    if (formik.values.city) {
      setDisabledSreet(false);
    } else {
      setDisabledSreet(true);
    }

    if (formik.values.city?.name === 'Київ') {
      setDisabledCityRegion(false);
      setDisabledCitySubregion(false);
    } else {
      setDisabledCityRegion(true);
      setDisabledCitySubregion(true);
    }

  }, [formik])

  return <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={6}>
      <LabeledAsyncSelect async={() => apartmentApi.operationTypes()} field="operationType" formik={formik} label={t('operationType')} placeholder={t('chooseOperationType')} required />
      <LabeledAsyncSelect async={() => apartmentApi.propertyTypes()} field="propertyType" formik={formik} label={t('rentType')} placeholder={t('chooseRentType')} required />
      <AddressAutocomplete disabled={disabled} field="state" formik={formik} label={t('region')} placeholder={t('chooseRegion')} required />
      <AddressAutocomplete disabled={disabledCity} field="city" formik={formik} label={t('city')} placeholder={t('chooseCity')} required />
      <AddressAutocomplete disabled={disabledCityRegion} field="cityRegion" formik={formik} label={t('district')} placeholder={t('chooseDistrict')} />
    </Grid>
    <Grid item xs={6}>
      <AddressAutocomplete disabled={disabledCitySubregion} field="citySubregion" formik={formik} label={t('microDistrict')} placeholder={t('chooseMicroDisrict')} />
      <AddressAutocomplete disabled={disabledStreet} field="street" formik={formik} label={t('street')} placeholder={t('chooseStreet')} required />
      <LabeledInput disabled={disabled} field="houseNumber" formik={formik} label={t('houseNumber')} placeholder={t('typeHouseNumber')} />
      <AddressAutocomplete disabled={disabled} field="residentialComplex" formik={formik} label={t('complex')} placeholder={t('chooseComplex')} />
      <Grid container columnSpacing={3}>
        <Grid item xs={8}>
          <LabeledInput disabled={disabled} field="price" formik={formik} label={t('price')} placeholder={t('objectType')} required />
        </Grid>
        <Grid item xs={4}>
          <LabeledSelect disabled={disabled} label={t('currency')} field="currency" formik={formik} options={[{ value: 0, name: "USD" }, { value: 1, name: "UAH" }]} placeholder={t('chooseCurrency')} />
        </Grid>
      </Grid>

    </Grid>
  </Grid>;
}

export function DetailInformationBlock({ formik }) {
  const { t } = useTranslation(["translation", "form"]);

  return <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={6}>
      <LabeledInput field="rooms" formik={formik} label={t('roomCount')} placeholder={t('typeRoomCount')} required />
      <LabeledInput endAdornment="м&#178;" field="totalArea" formik={formik} label={t('space')} placeholder={t('typeSpace')} required />
      <LabeledInput endAdornment="м&#178;" field="livingArea" formik={formik} label={t('liveSpace')} placeholder={t('typeLiveSpace')} />
      <LabeledInput endAdornment="м&#178;" field="kitchenArea" formik={formik} label={t('kitchenSpace')} placeholder={t('chooseKitchenSpace')} />
      <LabeledInput field="floor" formik={formik} label={t('floor')} placeholder={t('typeFloor')} required />
      <LabeledInput field="floorTotal" formik={formik} label={t('houseFloor')} placeholder={t('typeHouseFloor')} required />
      <LabeledAsyncSelect async={() => apartmentApi.repairTypes()} field="repair" formik={formik} label={t('repair')} placeholder={t('typeRepair')} required />
    </Grid>
    <Grid item xs={6}>
      <LabeledAsyncSelect async={() => apartmentApi.ownerTypes()} field="owner" formik={formik} label={t('owner')} placeholder={t('typeOwner')} required />
      <LabeledInput field="ownerName" formik={formik} label={t('ownerName')} placeholder={t('typeOwnerName')} />
      <InputPhoneMask field="phone" formik={formik} label={t('typeOwnerContact')} />
      <LabeledInput field="description" formik={formik} label={t('description')} multiline placeholder={t('objectDesctipyion')} rows={5} />
      <LabeledInput field="serviceInformation" formik={formik} label={t('staffInfo')} multiline placeholder={t('typeStaffInfo')} rows={5} />
    </Grid>
  </Grid>;
}
