import { t } from "i18next";

export const ApartmentBoardType = {
  Common: 0,
  Commercial: 1,
  Self: 2,
  Postponed: 3,
  Sent: 4,
  // Selected: 5,
  // Views: 6,

  getTitle(index) {
    switch (index) {
      case this.Common:
        return t('allViews');
      case this.Commercial:
        return t('ad');
      case this.Self:
        return t('myObjs');
      case this.Postponed:
        return t('postponed');
      default:
        return t('sent')
    }
  }
}

export const ClientApartmentBoardType = {
  Common: 0,
  Selected: 1,
  Sent: 2,
  Views: 3,

  getTitle(index) {
    switch (index) {
      case this.Common:
        return t('allViews');
      case this.Selected:
        return t('choosed');
      case this.Sent:
        return t('sent')
      default:
        return t('view');
    }
  }
}

export const FeedPortal = {
  olx: 0,
  flatfy: 1,
  dom_ria: 2,

  getTitle(index) {
    switch (index) {
      case this.olx:
        return "OLX";
      case this.flatfy:
        return "Flatfy";
      case this.dom_ria:
        return "dom.ria";
      default:
        return "Feed";
    }
  },

  getArray() {
    return [
      { portal: this.olx, title: this.getTitle(this.olx) },
      { portal: this.flatfy, title: this.getTitle(this.flatfy) },
      { portal: this.dom_ria, title: this.getTitle(this.dom_ria) },
    ]
  }
}

export const ApartmentOwnerType = {
  owner: 0,
  noCommission: 1,
  applyCommission: 2,
  payCommission: 3,

  getTitle(index) {
    switch (index) {
      case this.owner:
        return t('owner');
      case this.noCommission:
        return t('noCommission');
      case this.applyCommission:
        return t('applyCommission');
      case this.payCommission:
      default:
        return t('payCommission');
    }
  }
}