import { DetailInformationBlock, MainInformationBlock } from "components";
import { ApartmentFormik } from "helpers";
import React, { forwardRef, useImperativeHandle, useEffect } from "react";

const ApartmentInformation = forwardRef(({ apartment, handleSubmit }, ref) => {
  useImperativeHandle(ref, () => ({
    prepareData() {
      formik.handleSubmit();
    }
  }));
  const formik = ApartmentFormik(apartment, (data) => handleSubmit(data));

  return <>
    <MainInformationBlock formik={formik} />
    <DetailInformationBlock formik={formik} />
  </>
})

export default ApartmentInformation;