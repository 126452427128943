import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Typography } from "@mui/material";
import { stringToColour } from "helpers";
import React from "react";

export default function DragClientItem({ client }) {
  const fullName = client.lastName + " " + client.firstName + " " + client.middleName;
  const style = { height: 26, width: 26, borderRadius: 26, bgcolor: "newGrey.dark" };
  if (client.status) {
    style.bgcolor = stringToColour(client.id, 1);
  }
  return <Box sx={{ alignItems: "center", backgroundColor: "grey.light", display: "flex", justifyContent: "space-between", p: { xs: 2.4, xl: 3 }, width: "280px", transform: 'rotate(6deg)', WebkitTransform: 'rotate(6deg)' }}>
      <Box sx={{ alignItems: "center", justifyContent: "center", display: "flex", height: { xs: 38, xl: 48 }, minWidth: { xs: 38, xl: 48 }, mr: 2.5 }}>
        <Box sx={style} />
      </Box>
      <Typography variant="h4" sx={{ width: "100%", fontFamily: "FixelText Regular", fontSize: { xs: "14px", xl: "16px" } }}>{fullName}</Typography>
      <IconButton aria-label="menu" edge="end" sx={{ backgroundColor: "rgba(0, 0, 0, 0)", height: 24, width: 24 }}>
        <MoreVertIcon sx={{ height: 20, width: 20 }} />
      </IconButton>
    </Box>
}