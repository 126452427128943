import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, IconButton, Menu, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export function HeaderTimeRangePicker({ value, handleDateRangeChange, handleOpen }) {
  const { t } = useTranslation(["translation", "form"]);
  const { user } = useSelector(x => x.auth);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const anchorRef = useRef(null);

  const handleButtonClick = (e) => {
    setOpen((prevOpen) => {
      handleOpen(prevOpen);

      return !prevOpen;
    });
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
    handleOpen(true);
  };

  const dateStart = parseDate(value[0]?.toDate());
  const dateEnd = parseDate(value[1]?.toDate());
  const title = (dateStart || dateEnd) 
    ? ((dateStart ? `${t('c')} ${dateStart} ` : '') + (dateEnd ? ` ${t('__to')} ${dateEnd}` : '')) 
      : t('forAllPeriod');

  return <>
    <Typography variant="h5" sx={{fontWeight: "500", fontFamily: "Fixel Medium", lineHeight: "24px", fontSize:{xs:"13px", xl:"16px"}, color:"newGrey.dark"}}  ml={{xs: 6, xl: 7.5}}>{t('period')}:</Typography>
    <Button
      endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      disabled={!user.isCrmUser}
      onClick={handleButtonClick}
      ref={anchorRef}
      sx={{ fontWeight: "500", fontSize:{xs:"13px", xl:"16px"}, textWrap:"nowrap", minWidth:"unset", p:1.25, color:"dark"}}
      variant="text"
    >{title}</Button>
    
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ua'}>
        <DateRangeCalendar
          value={value}
          onChange={handleDateRangeChange}
        />
      </LocalizationProvider>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: -5, pr: 1 }}>
        <IconButton aria-label="clear" onClick={() => handleDateRangeChange([null, null])}>
          <DeleteForeverIcon />
        </IconButton>
      </Box>
    </Menu>
  </>
}

function parseDate(date) {
  let result = "";
  if (date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    result = `${day}.${month}`;
  }

  return result;
}