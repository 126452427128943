import ClearIcon from "@mui/icons-material/Clear";
import { ListItemText, MenuItem, Select } from "@mui/material";
import { Checkbox } from "components";
import { styled } from "@mui/material/styles";

export const CheckboxSelect = ({ name, onChange, onClear, options, placeholder, value }) => <FilterSelect
  fullWidth
  multiple
  onClose={() => {
    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  }}
  endAdornment={
    <ClearIcon fontSize="small" onClick={onClear} sx={{ display: value.length > 0 ? "" : "none", cursor: "pointer" }} />
  }
  name={name}
  onChange={onChange}
  placeholder={placeholder}
  renderValue={(selected) => options.filter((option) => selected.includes(option.value)).map((option) => option.name).join(', ')}
  size="small"
  sx={{
    mb: 0.5, '& .MuiSelect-select .notranslate::after': placeholder ? {
      content: `"${placeholder}"`, opacity: 0.42,
    } : {},
    "& .MuiSelect-iconOutlined": { display: value.length > 0 ? 'none' : '' }
  }}
  value={value}
>
  {options.map((option, key) => <MenuItem
    key={key}
    value={option.value}
  >
    <Checkbox checked={value.includes(option.value)} />
    <ListItemText primary={option.name} />
  </MenuItem>)}
</FilterSelect>

export const FilterSelect = styled(Select)(({ theme }) => ({
  fontSize: "14px"
}));