import { InputAdornment, TextField, Typography } from "@mui/material";
import React from 'react';

export const LabeledInput = ({autoFocus, disabled, endAdornment, field, formik, label, multiline, placeholder, required, rows}) => {

  const handleChangeValuePreview = (event, newValue) => {

    if (event) {
      const apartmentsList = JSON.parse(localStorage.getItem('currentPreview'));

      const currentApartment = apartmentsList.map(apartment => {
        if (apartment.id === formik.values.id) {
          return {...apartment, [field]: newValue}
        }
        return apartment;
      })
  
      localStorage.setItem('currentPreview', JSON.stringify(currentApartment))
    }
  }

  return <>
    <Typography className={required && "label"} variant="h4" mt={{ xs: 1.5, md: 3.75 }}>
      {label}
    </Typography>
    <TextField
      autoFocus={autoFocus}
      disabled={disabled}
      InputProps={{
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
        sx: multiline && { padding: "0" }
      }}

      error={formik.errors[field] && formik.touched[field]}
      fullWidth
      helperText={formik.touched[field] && formik.errors[field]}
      hiddenLabel
      margin="dense"
      multiline={multiline}
      name={field}
      // onChange={(event, newValue) => handleChange(event, newValue)}
      onChange={(event) => {
        formik.setFieldError(event.target.name, undefined);
        formik.handleChange(event)
        handleChangeValuePreview(event, event.target.value)
      }}
      placeholder={placeholder}
      rows={rows}
      type="text"
      variant="filled"
      value={formik.values[field]}
    />
  </>
}