import { iconButtonClasses, Typography } from "@mui/material";
import { DatePicker, datePickerToolbarClasses, LocalizationProvider, TimePicker, } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru';
import React from 'react';
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

export const DatePickerInput = ({ field, formik, label, placeholder, isTime = false, required = true }) => {
  const commonProps = {
    value: dayjs(formik.values[field]),
    onChange: (value) => formik.setFieldValue(field, value ? value.toDate() : null, true),
    slotProps: {
      textField: {
        margin: "dense",
        name: field,
        placeholder: placeholder,
        type: "text",
        fullWidth: true,
        variant: "filled",
        error: false
      }
    }
  }

  const Component = isTime ? StyledTimePicker : StyledDatePicker;
  return <>
    <Typography className={required && "label"} variant="h4" sx={{ mt: 3.75 }}>
      {label}
    </Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ua'}>
      <Component {...commonProps} />
    </LocalizationProvider>
  </>
}

const StyledTimePicker = styled(TimePicker)({
  [`& .${iconButtonClasses.root}`]: {
    backgroundColor: 'transparent',
    "& :hover": {
      backgroundColor: 'transparent',
    }
  },
});

const StyledDatePicker = styled(DatePicker)({
  [`& .${iconButtonClasses.root}`]: {
    backgroundColor: 'transparent',
    "& :hover": {
      backgroundColor: 'transparent',
    }
  },
  [`& .${datePickerToolbarClasses.root}`]:
    {
      [`& .${iconButtonClasses.root}`]: {
        backgroundColor: 'transparent',
        "& :hover": {
          backgroundColor: 'transparent',
        }
      },
    }
});