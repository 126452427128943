import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apartmentBoardApi, apartmentApi } from "helpers";

const name = "apartmentBoard";
const initialState = {list: [], client: { list: [], client: {} }};
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const apartmentBoardActions = { ...slice.actions, ...extraActions };
export const apartmentBoardReducer = slice.reducer;

function createReducers() {
  return {
    handleInternalDrop, handleExternalDrop, updateApartment, replaceApartmentBoards, replaceApartment, handleInternalSort
  };

  function handleInternalSort(state, { payload }) {

    if (payload.client) {
      state.client.list[payload.boardIndex].apartments = payload.data;
    } else {
      state.list[payload.boardIndex].apartments = payload.data;
    }
  }

  function handleInternalDrop(state, { payload }) {

    if (payload.client) {

      if (payload.dragIndex !== null) {
        state.client.list[payload.boardIndex].apartments.splice(payload.dragIndex, 1);
      }
    
      if (payload.hoverIndex !== null) {
        state.client.list[payload.boardIndex].apartments.splice(payload.hoverIndex, 0, payload.payload);
      }
  
      const newOrder = {};
      state.client.list[payload.boardIndex].apartments.filter((apartment) => apartment.status === 1).map((apartment, index) => {
        if (apartment.status) {
          newOrder[index] = apartment.orderPosition;
        }
      })
  
      return state;

    } else {

      if (payload.dragIndex !== null) {
        state.list[payload.boardIndex].apartments.splice(payload.dragIndex, 1);
      }
    
      if (payload.hoverIndex !== null) {
        state.list[payload.boardIndex].apartments.splice(payload.hoverIndex, 0, payload.payload);
      }
  
      const newOrder = {};
      state.list[payload.boardIndex].apartments.filter((apartment) => apartment.status === 1).map((apartment, index) => {
        if (apartment.status) {
          newOrder[index] = apartment.orderPosition;
        }
      })
  
      return state;

    }
  }

  function handleExternalDrop(state, { payload }) {


    if (payload.client) {

      if (payload.hoverIndex !== null) {

        state.client.list[payload.boardIndex].apartments.splice(payload.hoverIndex, 0, payload.payload);
      }

    } else {

      if (payload.hoverIndex !== null) {
        state.list[payload.boardIndex].apartments.splice(payload.hoverIndex, 0, payload.payload);
      }
  
      return state;

    }
  }

  function updateApartment(state, { payload }) {
    state.map(apartmentBoard => {
        apartmentBoard.apartments = apartmentBoard.apartments.map(apartment => {
        if (apartment.id === payload.id) {
            apartment = payload;
        }

        return apartment;
      })
      return apartmentBoard;
    })
  }

  function replaceApartmentBoards(state, { payload }) {
    state.splice(payload.hoverIndex, 0, state.splice(payload.dragIndex, 1)[0]);
  }

  function replaceApartment(state, { payload }) {
    const { dragIndex, hoverIndex, item, apartmentBoardId, toHead } = payload;
    const from = state.find(apartmentBoard => apartmentBoard.id === item.apartmentBoardId);
    const to = state.find(apartmentBoard => apartmentBoard.id === apartmentBoardId);

    let removeItem;
    if (item.isHead) {
      removeItem = from.head;
      from.head = null;
    } else {

      removeItem = from.apartments.find((apartment) => {
        if (apartment.id === item.id) {
          from.apartments.splice(dragIndex, 1)
        }

        return apartment.id === item.id;
      })
    }

    if (removeItem) {
      if (toHead) {
        if (to.head) {
          to.apartments.splice(hoverIndex, 0, to.head);
        }
        to.head = removeItem;
      } else {
        to.apartments.splice(
            apartmentBoardId === item.apartmentBoardId && !item.isHead && hoverIndex > item.index ? hoverIndex - 1 : hoverIndex,
          0,
          removeItem
        );
      }
    }
  }
}

function createExtraActions() {
  return {
    list: list(),
    clientAll: clientAll(),
    updateOrder: updateOrder(),
    updateAll: updateAll(),
    moveApartment: moveApartment(),
    copyApartment: copyApartment(),
    deleteFromBoard: deleteFromBoard(),
  };

  function deleteFromBoard() {
    return createAsyncThunk(
      `${name}/deleteFromBoard`, async (data) => await apartmentBoardApi.deleteFromBoard(data)
    )
  }
  
  function moveApartment() {
    return createAsyncThunk(`${name}/moveApartment`, async (data, thunkAPI) => {

      if (data.client) {

        const state = thunkAPI.getState();
        const newOrder = [];
        const currentBoard = state.apartmentBoard.client.list[data.moveAnBoard];
        currentBoard.apartments.filter((apartment) => apartment.status === 1).map((apartment, index) => {
          if(apartment.status) {
            newOrder[index] = apartment.id;
          }
        })
  
        return await apartmentBoardApi.moveApartment({board: currentBoard.id, data: newOrder})

      } else {

        const state = thunkAPI.getState();
        const newOrder = [];
        const currentBoard = state.apartmentBoard.list[data.moveAnBoard];
        currentBoard.apartments.filter((apartment) => apartment.status === 1).map((apartment, index) => {
          if(apartment.status) {
            newOrder[index] = apartment.id;
          }
        })
  
        return await apartmentBoardApi.moveApartment({board: currentBoard.id, data: newOrder})
      }
    })
  }

  function copyApartment() {
    return createAsyncThunk(`${name}/copyApartment`, async (data, thunkAPI) => {



      if (data.client) {

        const state = thunkAPI.getState();

        const newOrder = [];
        const currentBoard = state.apartmentBoard.client.list[data.copyAnBoard];
        
        currentBoard.apartments.map((apartment, index) => {
          newOrder[index] = apartment.id;
        })
  
        return await apartmentBoardApi.copyApartment({board: currentBoard.id, apartment: data.apartmentId, newIndex: data.newIndex, data: newOrder, client: data.client})

      } else {

        const state = thunkAPI.getState();

        const newOrder = [];
        const currentBoard = state.apartmentBoard.list[data.copyAnBoard];
        
        currentBoard.apartments.map((apartment, index) => {
          newOrder[index] = apartment.id;
        })
  
        return await apartmentBoardApi.copyApartment({board: currentBoard.id, apartment: data.apartmentId, newIndex: data.newIndex, data: newOrder})

      }
    })
  }

  function list() {
    return createAsyncThunk(`${name}/list`, async () => await apartmentBoardApi.list());
  }

  function clientAll() {
    return createAsyncThunk(`${name}/clientAll`, async (clientId) => await apartmentBoardApi.clientAll(clientId));
  }

  function updateOrder() {
    return createAsyncThunk(`${name}/updateOrder`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();
      const newOrder = {};
      state.apartmentBoard.map((apartmentBoard, index) => {
        newOrder[apartmentBoard.id] = index + 1;
        return newOrder;
      })

      return await apartmentBoardApi.updateOrder(newOrder)
    });
  }

  function updateAll() {
    return createAsyncThunk(`${name}/updateApartmentBoards`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();
      const newApartmentBoards = {};
      state.apartmentBoard.forEach(apartmentBoard => {
        newApartmentBoards[apartmentBoard.id] = {};
        newApartmentBoards[apartmentBoard.id].apartments = {};
        newApartmentBoards[apartmentBoard.id].head = apartmentBoard.head?.id;
        apartmentBoard.apartments.forEach((apartment, index) => {
            newApartmentBoards[apartment.id].apartments[apartment.id] = index + 1;
        })
      })
      return await apartmentBoardApi.updateAll(newApartmentBoards)
    });
  }
}

function createExtraReducers() {
  return {
    ...list(),
    ...clientAll(),
    ...moveApartment(),
    ...copyApartment(),
    ...deleteFromBoard()
  };

  function deleteFromBoard() {
    const { fulfilled } = extraActions.deleteFromBoard;
    return {
      [fulfilled]: (state, action) => {

        const { apartments, board, client } = action.meta.arg;

        if (client) {

          const old = state.client.list[board].apartments;
          state.client.list[board].apartments = old.filter((apartment) => !apartments.includes(apartment.id));
        } else {

          const old = state.list[board].apartments;
          state.list[board].apartments = old.filter((apartment) => !apartments.includes(apartment.id));
        }
      }
    };
  }

  function copyApartment() {
    const { fulfilled } = extraActions.copyApartment;
    return {
      [fulfilled]: (state, action) => {
        return;
      },
    };
  }

  function moveApartment() {
    const { fulfilled } = extraActions.moveApartment;
    return {
      [fulfilled]: (state, action) => {
        return;
      },
    };
  }

  function list() {
    const { fulfilled } = extraActions.list;
    return {
      [fulfilled]: (state, action) => {
        state.list = action.payload.result
      },
    };
  }

  function clientAll() {
    const { fulfilled } = extraActions.clientAll;
    return {
      [fulfilled]: (state, action) => {
        state.client.list = action.payload.result.apartmentBoards;
        state.client.client = action.payload.result.client;
      },
    };
  }
}
